.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: black;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.break-word-banner {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #21f4b0;
  width: 25px;
  border-radius: 5px;
}

@media screen and (max-width: 1400px) {
  .banner-title-responsive {
    font-size: 2.6rem !important;
  }
  .banner-description-responsive {
    font-size: 26px !important;
  }
}

@media screen and (max-width: 1100px) {
  .banner-title-responsive {
    font-size: 2.2rem !important;
  }
  .banner-description-responsive {
    font-size: 25px !important;
  }
}

@media screen and (max-width: 601px) {
  .banner-title-responsive {
    font-size: 2rem !important;
  }
  .banner-description-responsive {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 301px) {
  .banner-title-responsive {
    font-size: 1.6rem !important;
  }
  .banner-description-responsive {
    font-size: 15px !important;
  }
}
