.swiper {
  width: "100%";
  height: auto;
}

.document-slider .swiper-button-prev {
  color: white;
  height: 200px;
}

.document-slider .swiper-button-next {
  color: white;
  height: 200px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: fit-content;
  /* flex-shrink: 100 !important; */
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.break-word {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.swiper-pagination-bullet {
  background-color: white;
  opacity: 1;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #21f4b0;
  width: 25px;
  border-radius: 5px;
}

.swiper-slide.swiper-slide-active {
  width: 30;
}

.swiper-button-prev {
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  height: 180px;
  top: 0;
  margin: 0px;
  left: var(--swiper-navigation-sides-offset, 0px);
  padding-left: 5px;
  padding-right: 5px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  backdrop-filter: blur(5px);
}

.swiper-button-next {
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  height: 180px;
  top: 0;
  margin: 0px;
  right: var(--swiper-navigation-sides-offset, 0px);
  padding-left: 5px;
  padding-right: 5px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  backdrop-filter: blur(5px);
}
